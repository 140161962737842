import {useEffect, useState} from "react";
import { TableauViz, FilterUpdateType, Toolbar } from "@tableau/embedding-api";
import {generateTableauJWTToken} from "../../utils/jwt";

interface Filter {
    field: string;
    values: any;
    type?: FilterUpdateType;
}

interface TableauProps {
    url: string;
    token?: string;
    filters?: Filter[];
    className?: string;
    options?: {
        hideTabs?: boolean;
        toolbar?: Toolbar;
        width?: string;
        height?: string;
    }
}

const Tableau: React.FC<TableauProps> = ({ url, filters = [], options={}, className}) => {
    const [viz, setViz] = useState<TableauViz | null>(null);

    useEffect(() => {
        generateTableauJWTToken().then((token) => {
            const tableauViz = new TableauViz();
            tableauViz.src = url;
            tableauViz.token = token!;
            
            // Set options
            tableauViz.hideTabs = options.hideTabs ?? false;
            tableauViz.toolbar = options.toolbar ?? Toolbar.Hidden;
            tableauViz.width = options.width ?? "100%";
            tableauViz.height = options.height ?? window.innerHeight.toString();

            // Add filters
            filters.forEach(({ field, values }) => {
                tableauViz.addFilter(field, values);
            });

            setViz(tableauViz);
        });
    }, [url, filters, options]);

    return (
        <div className={className + " relative"}
            ref={ref => {
                if (viz && ref && ref.childElementCount < 1) {
                    ref.appendChild(viz)
                }
            }}
            id="tableau-viz"
        ></div>
    );
};

Tableau.defaultProps = {
    className: "flex-1",
    options: {
        hideTabs: false,
        toolbar: Toolbar.Hidden,
        width: "100%",
        height: window.innerHeight.toString()
    }
};

export default Tableau;