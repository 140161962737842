import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import MainApp from 'App/MainApp'
import { useDispatch, useSelector } from "react-redux";
import SignIn from "containers/SingIn";
import { selectAuth } from "containers/SingIn/authSlice";
import { fetchUserData } from "containers/SingIn/authAPI";
import { getToken } from "utils";
import GenToken from "containers/GenToken";
import useGaTracker from "../useGaTracker.js"
import ActivationProgramsPdf from "containers/ExportPdf/ActivationProgramsPdf";
import OverviewPdf from "containers/ExportPdf/OverviewPdf";
import AppUsagePdf from "containers/ExportPdf/AppUsagePdf";
import ClinicalInsightsPdf from "containers/ExportPdf/ClinicalInsightsPdf";
import DepartmentHeathPdf from "containers/ExportPdf/OverviewPdf/department_heath";
import LearningPacksPdf from "containers/ExportPdf/AppUsagePdf/learning_pack_pdf";
import FwdMetricChartPdf from "containers/ExportPdf/OverviewPdf/fwd_metric_chart_pdf";
import UserAcqisitionChartPdf from "containers/ExportPdf/ChartPdf/user_acqisition_chart_pdf";
import CountryBreakDownChart from "containers/ExportPdf/ChartPdf/country_break_down_chart";
import TopConcernsChart from "containers/ExportPdf/ChartPdf/top_concerns_chart";
import GenderBreakDownChart from "containers/ExportPdf/ChartPdf/gender_break_down_chart";
import AgeBreakDownChart from "containers/ExportPdf/ChartPdf/age_break_down_chart";
import MoodBreakDownChart from "containers/ExportPdf/ChartPdf/mood_break_down_chart";
import FreOfPosiMoodBasedOnReasonChart from "containers/ExportPdf/ChartPdf/frequency_of_positive_moods_based_on_reasons_chart";
import FreOfNegaMoodBasedOnReasonChart from "containers/ExportPdf/ChartPdf/frequency_of_negative_moods_based_on_reasons_chart";
import MoodFrequenciesChart from "containers/ExportPdf/ChartPdf/mood_frequencies_chart";
import FreqOfMoodsBasedOnDaysChart from "containers/ExportPdf/ChartPdf/frequency_of_moods_based_on_days_chart";
import ReasonSociWithTheTopMoodsChart from "containers/ExportPdf/ChartPdf/reasons_ssociated_with_the_top_moods_chart";
import UserOutComeBreakdownChart from "containers/ExportPdf/ChartPdf/user_outcome_break_down_chart";
import FeedbackOnTfProfsChart from "containers/ExportPdf/ChartPdf/feedback_on_tf_profs_chart";
import DassScoreOfMyOrgChart from "containers/ExportPdf/ChartPdf/dass_score_of_my_org_chart";
import MonthyDassScoreOfMyOrgChart from "containers/ExportPdf/ChartPdf/monthy_dass_score_of_my_org_chart";
import DassScoreOfThisMonthChart from "containers/ExportPdf/ChartPdf/dass_score_of_this_month_chart";
import ImpactRatingPdf from "containers/ExportPdf/ActivationProgramsPdf/ActivationProgramsItem/impact_rating";
import ActivationProgramsItemPdf from "containers/ExportPdf/ActivationProgramsPdf/ActivationProgramsItem";
import AiaOverviewPdf from "containers/AiaCs/ExportPDF/Overview";
import BehaviouralInsightsPdf from "containers/AiaCs/ExportPDF/BehaviouralInsights";
import AiaTopConcernsChart from "containers/AiaCs/ExportPDF/AppUsage/top_concert";
import AiaBreakDownGenderChart from "containers/AiaCs/ExportPDF/AppUsage/breakdown_gender";
import AiaBreakDownAgeChart from "containers/AiaCs/ExportPDF/AppUsage/breakdown_age";
import AiaTopUserOutcomesPdf from "containers/AiaCs/ExportPDF/TopUserOutcomes";
import AiaHealthOutcomesChartPdf from "containers/AiaCs/ExportPDF/HealthOutcomes";
import AiaAvgDassScoreChartPdf from "containers/AiaCs/ExportPDF/HealthOutcomes/avg_dass_score_chart";
import InterpretationDassScoresTable from "containers/AiaCs/HealthOutcomes/interpretation_dass_scores_table";
import MetricsDefinition from "containers/AiaCs/MetricsDefinition";

const RestrictedRoute = ({ component: Component, location, token, ...rest }: any) => {

  return (
    <Route
      {...rest}
      render={props =>
        token
          ? <Component {...props} />
          : <Redirect
            to={{
              pathname: '/signin',
              state: { from: location }
            }}
          />}
    />
  )
}



export default function App(props: any) {
  const { programId } = useSelector(selectAuth);
  const match = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserData(props.history, programId));
  }, []);
  useGaTracker();
  return (
    <Router>
      <Switch>
        <Route exact path='/token' component={GenToken} />
        <Route exact path='/signin' component={SignIn} />
        <Route path="/overview-pdf" component={OverviewPdf} />
        <Route path="/department-health-pdf" component={DepartmentHeathPdf} />
        <Route path="/fwd-metric-chart-pdf" component={FwdMetricChartPdf} />
        <Route path="/user-acqisition-chart" component={UserAcqisitionChartPdf} />
        <Route path="/country-break-down-chart" component={CountryBreakDownChart} />
        <Route path="/top-concerns-chart" component={TopConcernsChart} />
        <Route path="/gender-break-down-chart" component={GenderBreakDownChart} />
        <Route path="/age-break-down-chart" component={AgeBreakDownChart} />
        <Route path="/app-usage-pdf" component={AppUsagePdf} />
        <Route path="/learning-pack-pdf" component={LearningPacksPdf} />
        <Route path="/mood-break-down-chart" component={MoodBreakDownChart} />
        <Route path="/frequency-of-positive-moods-based-on-reasons-chart" component={FreOfPosiMoodBasedOnReasonChart} />
        <Route path="/frequency-of-negative-moods-based-on-reasons-chart" component={FreOfNegaMoodBasedOnReasonChart} />
        <Route path="/mood-frequencies-chart" component={MoodFrequenciesChart} />
        <Route path="/frequency-of-moods-based-on-days-chart" component={FreqOfMoodsBasedOnDaysChart} />
        <Route path="/reasons-ssociated-with-the-top-moods-chart" component={ReasonSociWithTheTopMoodsChart} />
        <Route path="/user-outcome-break-down-chart" component={UserOutComeBreakdownChart} />
        <Route path="/feedback-on-tf-profs-chart" component={FeedbackOnTfProfsChart} />
        <Route path="/dass-score-of-my-org-chart" component={DassScoreOfMyOrgChart} />
        <Route path="/monthy-dass-score-of-my-org-chart" component={MonthyDassScoreOfMyOrgChart} />
        <Route path="/dass-score-of-this-month-chart" component={DassScoreOfThisMonthChart} />
        <Route path="/clinical-insights-pdf" component={ClinicalInsightsPdf} />
        <Route path="/activation-programs-pdf" component={ActivationProgramsPdf} />
        <Route path="/activation-programs-detail-overview-pdf" component={ActivationProgramsItemPdf} />
        <Route path="/activation-programs-detail-impact-rating-pdf" component={ImpactRatingPdf} />


        <Route path="/aia-overview-pdf" component={AiaOverviewPdf} />
        <Route path="/aia-behavioural-insights-pdf" component={BehaviouralInsightsPdf} />
        <Route path="/aia-top-concert-chart-pdf" component={AiaTopConcernsChart} />
        <Route path="/aia-breakdown-gender-chart-pdf" component={AiaBreakDownGenderChart} />
        <Route path="/aia-breakdown-age-chart-pdf" component={AiaBreakDownAgeChart} />
        <Route path="/aia-top-user-outcomes-pdf" component={AiaTopUserOutcomesPdf} />
        <Route path="/aia-health-outcomes-chart-pdf" component={AiaHealthOutcomesChartPdf} />
        <Route path="/aia-avg-dass-scores-chart-pdf" component={AiaAvgDassScoreChartPdf} />
        <Route path="/aia-interpretation-dass-scores-table" component={InterpretationDassScoresTable} />
        <Route path="/aia-metrics-definition" component={MetricsDefinition} />



        <RestrictedRoute path={`${match.url}`} token={getToken()} location={location}
          component={MainApp} />
      </Switch>
    </Router>

  )
}
