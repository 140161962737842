import Tableau from "../../components/Tableau";
import {EXPORT_FILE, UTILIZATION_INSIGHTS} from "../../constants/tableau";
import {useSelector} from "react-redux";
import {selectAuth} from "../SingIn/authSlice";

const ExportPdf = () => {
    const {org} = useSelector(selectAuth);
    return (
        <main>
            <div className="bg-grey min-h-screen">
                {
                    org && <Tableau
                        className={'bg-white flex-1'}
                        options={{
                            hideTabs: true,
                        }}
                        filters={[
                            {
                                field: 'Organization Id',
                                values: [org?.id] //2, 58, 329
                            }
                        ]}
                        url={EXPORT_FILE}
                    />
                }
            </div>
        </main>
    );
}

export default ExportPdf