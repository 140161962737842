
import React from "react";
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    ChartBarIcon,
    UsersIcon,
    XIcon,
    ViewGridIcon,
    PresentationChartBarIcon,
    DownloadIcon,
    ChartPieIcon
} from '@heroicons/react/outline'

import Topbar from './Topbar';
import {
    Link, useLocation
} from "react-router-dom";
import images from 'assets/images'
import TopbarAiaCs from "containers/AiaCs/Topbar/topbar";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

// Define TypeScript types for navigation items
interface NavItem {
    name: string;
    href: string;
    icon: React.ComponentType<{ className: string; "aria-hidden": boolean }>;
    isShowDatePicker: boolean;
    isShowBtnDepartment: boolean;
    isShowBtnMartket: boolean;
    isShowApplyBtn: boolean;
    isShowTopDivider: boolean;
    isShowIcon: boolean;
}

// Define props type for Sidebar
interface SidebarProps {
    navigation: NavItem[];
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// Navigation configuration function
const navigationConfig = (isAiaCs: boolean): NavItem[] => {
    return isAiaCs
        ? [
            {
                name: "Thoughtfull X AIA CS",
                href: "/tf-aia-cs",
                icon: ChartPieIcon,
                isShowDatePicker: true,
                isShowBtnDepartment: false,
                isShowBtnMartket: false,
                isShowApplyBtn: true,
                isShowTopDivider: false,
                isShowIcon: false,
            },
        ]
        : [
            {
                name: "Overview",
                href: "/overview",
                icon: ViewGridIcon,
                isShowDatePicker: true,
                isShowBtnDepartment: true,
                isShowBtnMartket: true,
                isShowApplyBtn: true,
                isShowTopDivider: false,
                isShowIcon: false,
            },
            {
                name: "Utilization Insights",
                href: "/user-demographics",
                icon: UsersIcon,
                isShowDatePicker: true,
                isShowBtnDepartment: true,
                isShowBtnMartket: true,
                isShowApplyBtn: true,
                isShowTopDivider: false,
                isShowIcon: false,
            },
            {
                name: "Clinical Insights",
                href: "/clinical-insights",
                icon: ChartBarIcon,
                isShowDatePicker: true,
                isShowBtnDepartment: true,
                isShowBtnMartket: true,
                isShowApplyBtn: true,
                isShowTopDivider: false,
                isShowIcon: false,
            },
            {
                name: "Program Insights",
                href: "/activation-programs",
                icon: PresentationChartBarIcon,
                isShowDatePicker: true,
                isShowBtnDepartment: false,
                isShowBtnMartket: false,
                isShowApplyBtn: true,
                isShowTopDivider: false,
                isShowIcon: false,
            },
            {
                name: "Export PDF",
                href: "/export-pdf",
                icon: DownloadIcon,
                isShowDatePicker: true,
                isShowBtnDepartment: true,
                isShowBtnMartket: true,
                isShowApplyBtn: true,
                isShowTopDivider: true,
                isShowIcon: true,
            },
        ];
};

// Sidebar Component
const Sidebar: React.FC<SidebarProps> = ({ navigation, sidebarOpen, setSidebarOpen }) => (
    <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
            >
                <div className="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-primary">
                    <div className="absolute top-0 right-0 p-1">
                        <button
                            type="button"
                            className="cursor-pointer ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                            onClick={() => setSidebarOpen(false)}
                        >
                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="flex items-center justify-center flex-shrink-0 px-4 py-4">
                        <img
                            className="h-24 w-auto"
                            style={{ width: "176px", height: "45px" }}
                            src={images.logoTMW}
                            alt="Workflow"
                        />
                    </div>
                    <nav className="flex-1 px-2 pb-4 space-y-1">
                        {navigation.map((item) => (
                            <NavigationItemComponent key={item.name} item={item} />
                        ))}
                    </nav>
                </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
        </Dialog>
    </Transition.Root>
);

// Define props for NavigationItemComponent
interface NavigationItemComponentProps {
    item: NavItem;
}

// NavigationItem Component
const NavigationItemComponent: React.FC<NavigationItemComponentProps> = ({ item }) => {
    const location = useLocation();
    const isActive = window.location.href.includes(item.href);

    return (
        <>
            {item.isShowTopDivider &&
                <>
                    <div className="h-3"/>
                    <div className="border-t border-white content-center border-opacity-20"/>
                    <div className="h-3"/>
                </>
            }
            <Link
                to={{
                    pathname: item.href,
                    search: location.search,
                    state: {
                        isShowDatePicker: item.isShowDatePicker,
                        isShowBtnDepartment: item.isShowBtnDepartment,
                        isShowBtnMartket: item.isShowBtnMartket,
                        isShowApplyBtn: item.isShowApplyBtn,
                    },
                }}
                className={classNames(
                    isActive
                        ? "text-white font-bold bg-black bg-opacity-20"
                        : "text-white text-opacity-80 font-medium hover:bg-black hover:bg-opacity-20 hover:text-white",
                    "group flex items-center px-6 py-2 text-sm"
                )}
            >
                {item.isShowIcon && <item.icon className="mr-3 h-6 w-6 text-white" aria-hidden={true} />}
                {item.name}
            </Link>
        </>
    );
};

// Main Layout Component
const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const isAiaCs = window.location.href.includes("tf-aia-cs");
    const navigation = navigationConfig(isAiaCs);

    return (
        <div>
            <Sidebar navigation={navigation} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                <div className="flex flex-col flex-grow bg-primary overflow-y-hidden">
                    <div className="flex items-center justify-center flex-shrink-0 py-8">
                        <img
                            style={{ width: "176px", height: "45px" }}
                            className="h-24 w-auto"
                            src={images.logoTMW}
                            alt="Workflow"
                        />
                    </div>
                    <nav className="flex-1 space-y-1">
                        {navigation.map((item) => (
                            <NavigationItemComponent key={item.name} item={item} />
                        ))}
                    </nav>
                </div>
            </div>
            <div className="md:pl-64 flex flex-col flex-1">
                {isAiaCs ? <TopbarAiaCs setSidebarOpen={setSidebarOpen} /> : <Topbar setSidebarOpen={setSidebarOpen} />}
                <div>{children}</div>
            </div>
        </div>
    );
};

export default Layout;