import Tableau from "../../components/Tableau";
import {OVERVIEW_DASHBOARD} from "../../constants/tableau";
import {useSelector} from "react-redux";
import {selectAuth} from "../SingIn/authSlice";

const Overview = () => {
    const {org} = useSelector(selectAuth);
  return (
      <div className="bg-grey min-h-screen">
          {
              org && <Tableau
                  className={'bg-white flex-1'}
                  options={{
                      hideTabs: true,
                  }}
                  filters={[
                      {
                          field: 'Organization Id',
                          values: [org?.id] //2, 58, 329
                      }
                  ]}
                  url={OVERVIEW_DASHBOARD}
              />
          }
      </div>
  );
}

export default Overview