const images = {
  logo: require('./logo.png'),
  circleLogo:require('./circle_logo.png'),
  whiteLogo:require('./logo.png'),
  st1:require('./st1.png'),
  st2:require('./st2.png'),
  st3:require('./st3.png'),
  logoTMW: require('./logo.png'),
  mood_tracker: require('./mood_tracker.png'),
  dass: require('./dass.png'),
  learn_pack: require('./learn_pack.png'),
  aia_logo: require('./aia_logo.png'),
  tf_aia_logo: require('./tf_aia_logo.png'),
  filter_btn: require('./filter_button.png')

}

export default images