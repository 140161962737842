import {v4 as uuidv4} from 'uuid';
import {SignJWT} from 'jose';

export const generateTableauJWTToken = async () => {
    const connectedAppSecretKey = process.env.REACT_APP_TABLEAU_APP_SECRET_KEY;
    const connectedAppSecretId = process.env.REACT_APP_TABLEAU_APP_SECRET_ID;
    const connectedAppClientId = process.env.REACT_APP_TABLEAU_APP_CLIENT_ID;
    const scopes = ["tableau:views:embed", "tableau:views:embed_authoring"];
    const userId = process.env.REACT_APP_TABLEAU_USER_ID;
    const tokenExpiryInMinutes: number = parseInt(process.env.REACT_APP_TABLEAU_TOKEN_EXPIRY ?? '10'); // Max of 10 minutes.
    
    const userAttributes = {
        //  User attributes are optional.
        //  Add entries to this dictionary if desired.
        //  "[User Attribute Name]": "[User Attribute Value]",
    };

    const header = {
        kid: connectedAppSecretId,
        iss: connectedAppClientId,
        alg: 'HS256',
        typ: 'JWT',
    };

    const data = {
        iss: connectedAppClientId,
        jti: uuidv4(),
        aud: "tableau",
        sub: userId,
        scp: scopes,
        exp: Math.floor(Date.now() / 1000) + tokenExpiryInMinutes * 60,
        ...userAttributes,
    };

    const secret = new TextEncoder().encode(connectedAppSecretKey); // Convert secret to Uint8Array

    try {
         // Sign the token with the secret
        return await new SignJWT(data)
            .setProtectedHeader(header) // Set custom headers
            .setIssuedAt() // Set the issued at timestamp
            .sign(secret);
    } catch (error) {
        console.error('Error generating JWT:', error);
    }

    return null;
}