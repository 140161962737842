import { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { selectAuth } from 'containers/SingIn/authSlice';
import { sendOTPAction } from 'containers/SingIn/authAPI';
import OTPInput from "react-otp-input";

const TypeOTP = (props: any) => {
    const dispatch = useDispatch();
    const [otp, setOTP] = useState('')
    const { useremail, messageOTP } = useSelector(selectAuth);

    const submitOTP = () => {
        dispatch(sendOTPAction({ otp, useremail, history: props.history }))
    }

    return (
        <>
            <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray500">
                    Enter the 6-digit OTP code sent to your email address.
                </label>
                <div className="mt-4 w-full">
                    <OTPInput
                        value={otp}
                        inputStyle={`rounded-md border ${messageOTP ? "border-[#E41E28]" : "border-[#C8D2D9]"} !w-12 h-12 mx-2.5 text-center focus:outline-none focus:border-primary`}
                        onChange={setOTP}
                        numInputs={6}
                        shouldAutoFocus
                        containerStyle={"flex justify-center"}
                        renderInput={(props) => <input {...props} />}
                    />
                    {
                        messageOTP === "" ? null :
                            <div className={`${messageOTP && "text-error"} absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none`}>
                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                            </div>
                    }

                </div>
                <p className="mt-2 text-sm text-red-600 text-error">
                    {messageOTP}
                </p>
            </div>
            <div>
                <button
                    onClick={submitOTP}
                    disabled={otp.length < 6}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-normal text-white bg-primary hover:bg-primaryHeavy focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    Verify OTP
                </button>
            </div>
        </>
    )
}
export default TypeOTP