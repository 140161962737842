

type OverViewCard1Props = {
    className: string
    value: any
    title: string
    description: string
    subtitle?: string
    unit?: string
    listSubDescription?: string[],
    bgDescription?: string
    colorTitle?: string
    fontWeight?: number
}

const OverViewCard1 = (props: OverViewCard1Props) => {
    const {
        className,
        title,
        subtitle,
        value,
        unit,
        description,
        listSubDescription,
        bgDescription,
        colorTitle,
        fontWeight
    } = props;
    return (
        <div style={{ background: bgDescription }} className={className}>
            <div className="px-4 py-5 sm:p-6 bg-white">
                <h3
                    style={{ color: colorTitle || '', fontWeight: fontWeight || 400 }}
                    className="text-base leading-6 font-normal text-primary">{title}</h3>
                <span style={{ color: "#59817D" }} className="text-xs">
                    {subtitle}
                </span>
                <div className="mt-2 font-bold max-w-xl text-3xl text-primary">
                    <p>{value === -1 ? "Unlimited" : Number(value).toLocaleString()}{" "}
                        <span style={{ color: "#59817D" }} className="text-sm font-normal">
                            {unit}
                        </span></p>

                </div>
            </div>
            {listSubDescription && listSubDescription.length > 0 && (
                <div
                    style={{ color: "#009383", }}
                    className="px-4 py-3 rounded-b-lg">
                    {description}
                    <div>
                        <ul className="list-disc list-inside text-xs text-gray500 leading-4	">
                            {listSubDescription && listSubDescription.map(subDes => (
                                <li className="mt-2">
                                    {subDes}
                                </li>

                            ))}
                        </ul>
                    </div>
                </div>
            )}

        </div >
    )
}

export default OverViewCard1

