import TypeEmail from "./Step/TypeEmail";
import TypeOTP from "./Step/TypeOTP";
import LogoRight from "assets/images/screenshot_desktop.png"
import Logo from "assets/images/splash_icon.png";
import { useSelector } from "react-redux";
import { selectAuth } from "./authSlice";
import "./signin.css"
const SignIn = (props: any) => {
  const { useremail } = useSelector(selectAuth);

  return (
    <>
      <div className="min-h-full flex">
        <div className="left-column flex-1 px-20 py-20">
          <div className="form mx-auto w-full max-w-sm lg:w-96 mt-16">
            <div className="flex items-center justify-center logo-login">
              <img className="h-16 w-auto my-10" src={Logo} alt="Workflow" />
            </div>
            <div className="">
              <div className="flex flex-col items-center justify-center">
                <div className="text-xl">
                  <h1 className="font-bold text-secondary text-center">
                    Login to ThoughtFull Insights
                  </h1>
                </div>
              </div>
              <div className="mt-6">
                <div className="space-y-6">
                  {!useremail ? <TypeEmail /> : <TypeOTP {...props} />}
                  <div className="flex items-center justify-center login-service-area">
                    <div className="text-sm">
                      <a
                        href="https://www.thoughtfull.world/clients-terms-of-use"
                        target="_blank"
                        className="font-normal underline text-[#4D5D6D] hover:text-primary"
                      >
                        Terms of Use
                      </a>
                    </div>
                    <div className="text-sm">
                      <a
                        href="#"
                        className="font-normal text-[#4D5D6D] hover:text-primary"
                      >
                        &nbsp;|&nbsp;
                      </a>
                    </div>
                    <div className="text-sm">
                      <a
                        href="https://www.thoughtfull.world/privacy-policy"
                        target="_blank"
                        className="font-normal underline text-[#4D5D6D] hover:text-primary"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="right-column flex-2 h-screen hidden lg:block relative bg-primary bg-no-repeat bg-cover bg-center ">
          <div className="h-full flex flex-col items-center justify-center">
            <div className="top-section text-xl px-28 mt-8">
              <div className="justify-center justify-items-center">
                <h1 className="py-5 px-10 w-1/2 font-bold text-white text-center font-size-32 leading-tight">
                  All-in-One Platform for Employee Wellness and Progress
                </h1>
              </div>
              <div className="list-content flex mt-8 justify-center items-center">
                <div className="grid grid-rows-1 grid-flow-col gap-6 w-full max-w-md">
                  <div
                      className="bg-gradient-to-b from-[#1CE5C399] to-primary text-white rounded-md shadow p-4 h-full w-full min-w-0 text-sm text-center">
                    Gain real-time insights to make informed, data-driven decisions
                  </div>
                  <div
                      className="bg-gradient-to-b from-[#1CE5C399] to-primary text-white p-4 w-full min-w-0 rounded-md shadow text-sm text-center">
                    Stay updated on progress with the latest employee well-being metrics
                  </div>
                  <div
                      className="bg-gradient-to-b from-[#1CE5C399] to-primary text-white p-4 w-full min-w-0 rounded-md shadow text-sm text-center">
                    Be empowered with actionable recommendations to support your people
                    better
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-14 photo-screen">
              <img className="" src={LogoRight} alt=""/>
            </div>
            <p className="text-white font-size-14 mt-6">Always seamless. Always fuss-free.</p>

          </div>
        </div>
      </div>
    </>
  );
};
export default SignIn;
