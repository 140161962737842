import Tableau from "../../components/Tableau";
import {PROGRAM_INSIGHTS} from "../../constants/tableau";

const ActivationPrograms = () => {
  return (
      <main>
        <div className="bg-grey min-h-screen">
          <Tableau
              className={'bg-white flex-1'}
              options={{
                hideTabs: true,
              }}
              url={PROGRAM_INSIGHTS}/>
        </div>
      </main>
  );
}

export default ActivationPrograms