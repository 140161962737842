

type OverViewCard2Props = {
    className: string
    value: any;
    description: string
    percent?: boolean
    total_active_coachees?: string | number
    total_active_user?: string | number
    total_matched_users?: string | number
}

const OverViewCard2 = (props: OverViewCard2Props) => {

    return (
        <div className={props.className}>
            <div className="px-4 py-5 sm:p-6">
                <div className="mt-2 font-bold max-w-xl text-3xl text-primary">
                    <p>{props.value}{props.percent && "%"}</p>
                </div>
                <div className="mt-2 py-2 rounded-b-lg text-blue">
                    {props.description}
                </div>
                <div className="mt-2 py-2 rounded-b-lg ">
                    (
                    {(props.total_active_user || props.total_active_user === 0) && <span className="italic">
                        {props.total_active_user} total registered users</span>}
                    {(props.total_active_coachees ||
                        props.total_active_coachees === 0) && <span className="italic">
                            {props.total_active_coachees} active coachees</span>}

                    {(props.total_matched_users ||
                        props.total_matched_users === 0) && <span className="italic">
                            {props.total_matched_users} active users</span>}
                    )
                </div>
            </div>
        </div>
    )
}

export default OverViewCard2

